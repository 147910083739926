.f-container {
  margin: 0px 3vw 0px 3vw; 
  height: calc(100%-48vh);
  padding-bottom: 4vh;
}

.f-shelf {
  display: flex;
  align-items: flex-end;
}

.f-shelf-row {
  height: min-content;
  width: 100%;
}


.f-shelf-c1 {
  padding-right: 20vh;
  display: flex;
  align-items: flex-end;
}

.f-shelf-c2 {
  transition: 0.5s ease;
}
.f-shelf-c2:hover {
  background-color: var(--lightergray);
  transition: 0.5s ease;
}
.f-shelf-c2:after {
  transition-delay: 1s;
}

.f-shelf-c3 {
  margin-top: 40px;
  transition: 0.5s ease;
}
.f-shelf-c3:hover {
  background-color: var(--lightergray);
  transition: 0.5s ease;
}
.f-shelf-c3:after {
  transition-delay: 1.2s;
}

.f-shelf-c4 {
  margin-top: 20px;
  transition: 0.5s ease;
}
.f-shelf-c4:hover {
  background-color: var(--lightergray);
  transition: 0.5s ease;
}
.f-shelf-c4:after {
  transition-delay: 1.4s;
}

.f-shelf-c5 {
  transition: 0.5s ease;
  padding-bottom: 80px;
}
.f-shelf-c5:hover {
  background-color: var(--lightergray);
  transition: 0.5s ease;
}
.f-shelf-c5:after {
  transition-delay: 1.6s;
}

.f-shelf-label {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  writing-mode: vertical-lr;
  padding: 0;
  padding-bottom: 2vh;
  padding-top: 2vh;
  padding-right: 0.5vw;
  

  color: var(--lightgray);
  
}

.f-shelf-label:hover > .c-shelf-label-txt {
  opacity: 100;
  transition: 0.5s ease;
}

.f-initials {
  height: 5vh;
  width: auto;

  margin-bottom: -15px;

  
}

@media screen and (max-width: 992px) {
  .f-container {
    margin: 100px 24px 0px 24px; 
    height: min-content;
    padding-bottom: 24px;
  }
  .f-shelf {
    border-bottom: none;
  }
  .f-shelf-c1 {
    padding-right: 0;
    border-right: 2px solid var(--black);
    border-bottom: 2px solid var(--black);
  }

}